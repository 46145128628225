import type { FC } from 'react';
import { Outlet } from 'react-router-dom';

import { VersionInfoContainer } from '@saturn/uikit';

import { NavigationBar } from '../../../NavigationBar';
import { LayoutProps } from '../../models';

import styles from './MainLayout.module.scss';

export const MainLayout: FC<LayoutProps> = () => {
  const infoFE = {
    tag: process.env.REACT_APP_BUILD_ARG,
    commitHash: process.env.REACT_APP_COMMIT_HASH_ARG,
    deploymentTimeStamp: process.env.DEPLOY_TIMESTAMP,
  };

  const content = (
    <div className={styles.container}>
      <div className={styles.navigationBar}>
        <NavigationBar />
      </div>
      <div className={styles.main}>
        <Outlet />
      </div>
    </div>
  );

  return (
    <VersionInfoContainer infoFE={infoFE} project="admin">
      {content}
    </VersionInfoContainer>
  );
};
