import {
  ImportCategoryLandingPageResponse,
  ImportEventPageResponse,
  ImportLocationLandingPageResponse,
  ImportProductLandingPageResponse,
} from '../../models/dto/ExportImportDto';
import { axiosClient as api } from '../axiosClient';

// event
export async function exportEventLandingPage({
  locationLandingId,
  fileName,
}: {
  locationLandingId: string;
  fileName: string;
}): Promise<string> {
  const { data } = await api.get<string>(`/admin/location-event/landing/export/${locationLandingId}`, {
    params: {
      fileName,
    },
  });
  return data;
}
export async function importEventLandingPage({
  locationLandingId,
  form,
}: {
  locationLandingId: string;
  form: FormData;
}): Promise<ImportEventPageResponse> {
  const { data } = await api.post<ImportEventPageResponse>(
    `/admin/location-event/landing/import/${locationLandingId}`,
    form,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  return data;
}

// category page
export async function exportCategoryLandingPage({
  categoryLandingId,
  fileName,
}: {
  categoryLandingId: string;
  fileName: string;
}): Promise<string> {
  const { data } = await api.get<string>(`/admin/categories/export/${categoryLandingId}`, {
    params: {
      fileName,
    },
  });
  return data;
}
export async function importCategoryLandingPage({
  categoryLandingId,
  form,
}: {
  categoryLandingId: string;
  form: FormData;
}): Promise<ImportCategoryLandingPageResponse> {
  const { data } = await api.post<ImportCategoryLandingPageResponse>(
    `/admin/categories/import/${categoryLandingId}`,
    form,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  return data;
}

// product page
export async function exportProductLandingPage({
  productLandingId,
  productLandingType,
  fileName,
}: {
  productLandingId: string;
  productLandingType: string;
  fileName: string;
}): Promise<string> {
  const { data } = await api.get<string>(`/admin/products/export/${productLandingType}/${productLandingId}`, {
    params: {
      fileName,
    },
  });
  return data;
}

export async function importProductLandingPage({
  productLandingId,
  productLandingType,
  form,
}: {
  productLandingId: string;
  productLandingType: string;
  form: FormData;
}): Promise<ImportProductLandingPageResponse> {
  const { data } = await api.post<ImportProductLandingPageResponse>(
    `/admin/products/import/${productLandingType}/${productLandingId}`,
    form,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  return data;
}

// location landing page
export async function exportLocationLandingPage({
  locationLandingId,
  fileName,
}: {
  locationLandingId: string;
  fileName: string;
}): Promise<string> {
  const { data } = await api.get<string>(`/admin/locations/export/${locationLandingId}`, {
    params: {
      fileName,
    },
  });
  return data;
}

export async function importLocationLandingPage({
  locationLandingId,
  form,
}: {
  locationLandingId: string;
  form: FormData;
}): Promise<ImportLocationLandingPageResponse> {
  const { data } = await api.post<ImportLocationLandingPageResponse>(
    `/admin/locations/import/${locationLandingId}`,
    form,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  return data;
}
