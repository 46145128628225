import { useState } from 'react';
import type { FC } from 'react';
import StickyBox from 'react-sticky-box';

import { useRequest } from 'ahooks';
import isNil from 'lodash/isNil';

import { FormAndCardViewProductDto, InsuranceCategoryDto, LocationDetailsDto, LocationDto, admin } from '@saturn/api';
import { Button, Card, Form, FormSubmitFailed, Icon, Input, Spin, Tooltip, notification } from '@saturn/uikit';

import { PageHeader } from 'shared/components';
import { SidebarList } from 'shared/components/SidebarList/SidebarList';
import { DefaultIndexValue, IndicesType } from 'shared/components/SidebarList/types';
import { PROMPT_MESSAGE } from 'shared/constants';
import {
  getVisibleSections,
  onFormFinishFailed,
  revalidateAfterVisibilityChanged,
  useAdminLocation,
  usePrompt,
} from 'shared/utils';

import { LocationTabs } from 'features/location-landing/components/LocationTabs';

import { LocationTogglers } from '../features/location-landing/components/LocationTogglers';
import LOCATION_SECTION_TITLES from '../features/location-landing/constants';
import ExportImport from '../shared/components/ExportImport/ExportImport';
import useExportImportEnabled from '../shared/hooks/useExportImportEnabled';
import downloadJson from '../shared/utils/downloadJson';

import styles from './styles.module.scss';

const LocationLandingPage: FC = () => {
  const { adminLocation, locationLanguage } = useAdminLocation();
  const [formLocation] = Form.useForm<LocationDto>();
  const [form] = Form.useForm<LocationDetailsDto>();
  const locationId = adminLocation?.id;
  const [indices, setIndices] = useState<IndicesType>([]);

  const [isShowLeaveModal, setShowLeaveModal] = useState<boolean>(false);
  const [selectedInsuranceCategories, updateSelectedInsuranceCategories] = useState<string[]>([]);
  const [selectedInsuranceProducts, updateSelectedInsuranceProducts] = useState<string[]>([]);
  const [selectedInsurancePartners, updateSelectedInsurancePartners] = useState<string[]>([]);
  const [sectionsVisibility, setSectionsVisibility] = useState<Record<string, boolean | null>>({});
  const [defaultIndices, setDefaultIndices] = useState<Record<string, DefaultIndexValue>>({});

  usePrompt(PROMPT_MESSAGE, isShowLeaveModal);

  //<editor-fold desc="API Requests">
  const { data: location, loading: isLocationLoading } = useRequest(() => admin.getLocation(locationId), {
    refreshDeps: [locationId],
    onSuccess: data => {
      if (data) {
        formLocation.setFieldsValue(data);
      }
    },
  });
  const setFormDetailsFromResponse = (data: LocationDetailsDto) => {
    form.setFieldsValue(data);
    disableCategories(data.americanExpressInsurance?.categories);
    disableProducts(
      data.ourInsuranceProducts?.cardViewProducts ?? [],
      data.ourInsuranceProducts?.formViewProducts ?? [],
    );
    setSectionsVisibility(getVisibleSections(data));
    setDefaultIndices(
      Object.fromEntries(
        Object.entries(data).map(([key, value]) => [
          key,
          { order: value?.order ?? 0, subSections: value?.subSections ?? null },
        ]),
      ),
    );
  };
  const { data: locationDetails, loading: locationDetailsDataLoading } = useRequest(
    async () => admin.getLocationDetails(adminLocation, locationLanguage),
    {
      refreshDeps: [locationLanguage, adminLocation],
      onSuccess: data => {
        if (data) {
          setFormDetailsFromResponse(data);
        }
      },
    },
  );

  const { data: locationCategories = [] } = useRequest(() => admin.getCategoriesList(locationId), {
    refreshDeps: [locationId],
  });

  const { data: locationProducts = [] } = useRequest(() => admin.getProductForLocation(locationId), {
    refreshDeps: [locationId],
  });

  const { data: providers = [] } = useRequest(
    async () => (await admin.getProvidersList(adminLocation?.id)).map(({ title, id }) => ({ label: title, value: id })),
    {
      refreshDeps: [adminLocation?.id],
    },
  );

  const { runAsync: updateLocation } = useRequest(admin.updateLocation, { manual: true });
  const { runAsync: updateLocationDetails } = useRequest(admin.updateLocationDetails, { manual: true });
  //</editor-fold>

  const disableCategories = (selectedCategories: InsuranceCategoryDto[] | undefined) => {
    const result =
      selectedCategories
        ?.filter(cat => cat)
        ?.map(({ categoryId }) => categoryId)
        ?.filter(id => id) ?? [];

    updateSelectedInsuranceCategories(result);
  };

  const disableProducts = (
    selectedCardViewProducts: FormAndCardViewProductDto[],
    selectedFormViewProducts: FormAndCardViewProductDto[],
  ) => {
    const products = [...selectedCardViewProducts, ...selectedFormViewProducts];
    const result = [
      ...new Set(products.map((item: { productId: string }) => item?.productId).filter((id: string) => id)),
    ] as string[];

    updateSelectedInsuranceProducts(result);
  };

  const disableProviders = (insuranceProviderIds: string[]) => {
    const providers = insuranceProviderIds;
    const result = [...new Set(providers)] as string[];
    updateSelectedInsurancePartners(result);
  };

  const onFormFinish = async () => {
    try {
      const locationData = await formLocation.validateFields();
      const locationLandingData = await form.validateFields();

      const indexedValues = indices.reduce((res, item, i) => {
        const itemKey: string = item[0];
        // @ts-ignore
        const block = res[itemKey];
        if (block && typeof block == 'object') {
          // @ts-ignore
          block.order = i;
        }
        if ('subSections' in block) {
          const indItem = indices.find(ind => ind[0] === itemKey);
          const itemSubSections = indItem && indItem[1]?.subSections;
          itemSubSections?.forEach((subSection, subSectionIndex) => {
            block.subSections[subSection[0]].order = subSectionIndex;
          });
        }
        return res;
      }, locationLandingData);

      if (location && locationLanguage) {
        setShowLeaveModal(false);
        await Promise.all([
          updateLocation(locationId, locationData),
          updateLocationDetails(locationId, locationLanguage, indexedValues),
        ]).then(values => {
          if (values.some(value => !isNil(value))) {
            notification.success({
              message: 'Location has been successfully updated',
            });
          }
          return null;
        });
      }
      return true;
    } catch (e) {
      onFormFinishFailed(e as FormSubmitFailed);
    }
  };
  const pageTitle = 'Homepage';
  const envId = window.__RUNTIME_CONFIG__.ENVIRONMENT_ID ?? 'e1';
  const exportName = `${adminLocation.id}_${locationLanguage}_Location_${pageTitle}_${envId}_${new Date().getTime()}`;

  const locationLandingId = locationDetails?.id ?? '';
  const { runAsync: exportPage, loading: exportLoading } = useRequest(
    () => admin.exportLocationLandingPage({ locationLandingId, fileName: exportName }),
    {
      manual: true,
      onSuccess: async base64Data => {
        downloadJson(base64Data, exportName);
        notification.success({
          message: 'Page exported successfully',
        });
      },
    },
  );

  const { runAsync: importPage, loading: importLoading } = useRequest(
    (form: FormData) => admin.importLocationLandingPage({ locationLandingId, form }),
    {
      manual: true,
      onSuccess: response => {
        if (response.landingContent) {
          setFormDetailsFromResponse(response.landingContent);
          notification.success({
            message: 'Page imported successfully',
          });
        }
      },
    },
  );

  const isExportImportEnabled = useExportImportEnabled();

  return (
    <main>
      {adminLocation && <PageHeader title={pageTitle} subTitle="Edit Home Page" />}
      <Spin spinning={isLocationLoading && locationDetailsDataLoading}>
        {location && locationDetails ? (
          <Form.Provider onFormFinish={onFormFinish}>
            <div className={styles.stickyContainer}>
              <div className={styles.content}>
                {isExportImportEnabled && (
                  <ExportImport
                    exportLoading={exportLoading}
                    importLoading={importLoading}
                    onExportPage={exportPage}
                    onImportPage={importPage}
                    hasUnsavedChanges={isShowLeaveModal}
                    onSaveChangesAndExport={async () => {
                      const submitSuccess = await onFormFinish();
                      if (submitSuccess) {
                        await exportPage();
                      }
                    }}
                  />
                )}
                <Form
                  name="location"
                  form={formLocation}
                  onFinishFailed={onFormFinishFailed}
                  preserve={false}
                  onValuesChange={() => {
                    !isShowLeaveModal && setShowLeaveModal(true);
                  }}
                  initialValues={location}
                >
                  <Form.Item
                    name="slugPart"
                    rules={[
                      { required: true, message: 'Slug is required' },
                      { max: 40, message: 'Slug should be at most 40 characters' },
                      { min: 3, message: 'Slug should be at least 3 characters' },
                      {
                        pattern: /^[A-Za-z\d-]*$/,
                        message: 'There are restricted symbols in the value',
                      },
                    ]}
                  >
                    <Input
                      label="Slug"
                      addonAfter={
                        <Tooltip
                          title={
                            'SLUG is the last part of url after the last "/". Please note that the page of a location is not available by old url after change of slug. The value may contain Roman characters, digits, and dashes. Min-max size of the value is 3-40 chars and it must be unique.'
                          }
                        >
                          <Icon size={35} name="question-mark-circle-outline" />
                        </Tooltip>
                      }
                    />
                  </Form.Item>
                  <LocationTogglers />
                </Form>
                <Form
                  form={form}
                  name="locationDetails"
                  onFinishFailed={onFormFinishFailed}
                  preserve={false}
                  onValuesChange={(changedValue, allValues) => {
                    if (changedValue.americanExpressInsurance) {
                      disableCategories(allValues.americanExpressInsurance.categories);
                    } else if (changedValue.ourInsuranceProducts) {
                      disableProducts(
                        allValues.ourInsuranceProducts.cardViewProducts,
                        allValues.ourInsuranceProducts.formViewProducts,
                      );
                    } else if (changedValue.insurancePartners) {
                      disableProviders(allValues.insurancePartners.insuranceProviderIds);
                    }
                    setSectionsVisibility(getVisibleSections(allValues));
                    !isShowLeaveModal && setShowLeaveModal(true);
                  }}
                  onFieldsChange={(changedFields, allFields) =>
                    revalidateAfterVisibilityChanged(form, changedFields, allFields)
                  }
                  initialValues={locationDetails}
                >
                  <LocationTabs
                    isShowLeaveModal={isShowLeaveModal}
                    categories={locationCategories}
                    products={locationProducts}
                    selectedInsuranceCategories={selectedInsuranceCategories}
                    selectedInsurancePartners={selectedInsurancePartners}
                    selectedInsuranceProducts={selectedInsuranceProducts}
                    sectionTitles={LOCATION_SECTION_TITLES}
                    isSecondaryFooterDisclosureVisible={!!locationDetails.secondaryFooterDisclosure}
                    providers={providers}
                  />
                </Form>
              </div>
              <StickyBox offsetTop={20} offsetBottom={20} className={styles.stickyBlock}>
                <Button type="primary" htmlType="submit" onClick={onFormFinish}>
                  Save
                </Button>
                <Card title="Layout" bordered={false} className={styles.sidebarCardWrapper}>
                  <SidebarList
                    parent="location"
                    sidebarListValues={sectionsVisibility}
                    sectionTitles={LOCATION_SECTION_TITLES}
                    defaultIndices={defaultIndices}
                    indices={indices}
                    setIndices={setIndices}
                  />
                </Card>
              </StickyBox>
            </div>
          </Form.Provider>
        ) : (
          <Spin spinning={true}>{''}</Spin>
        )}
      </Spin>
    </main>
  );
};

export default LocationLandingPage;
