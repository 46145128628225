import { Fragment, useEffect, useState } from 'react';
import type { ReactNode } from 'react';

import { useRequest } from 'ahooks';
import dayjs from 'dayjs';
import omit from 'lodash/omit';

import { admin, api as customer, titan } from '@saturn/api';
import type { VersionInfo } from '@saturn/api';

import { Modal } from '../Modal';

export interface VersionInfoContainerProps {
  children?: ReactNode;
  disabled?: boolean;
  infoSSR?: VersionInfo;
  infoFE: VersionInfo;
  project: 'admin' | 'customer' | 'titan';
}

const endpoints = {
  admin: admin.runInfoRequest,
  titan: titan.runInfoRequest,
  customer: customer.runInfoRequest,
};

export function VersionInfoContainer({
  children,
  disabled = false,
  infoFE,
  infoSSR,
  project,
}: VersionInfoContainerProps): JSX.Element {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { data: infoBE, run: getBEInfo } = useRequest(endpoints[project], {
    manual: true,
    onFinally: () => {
      setIsModalVisible(true);
    },
  });

  useEffect(() => {
    if (!disabled) {
      const handleKeyPress = (event: KeyboardEvent) => {
        // Use `Ctrl + Shift + Option + V` for MacOS / `Ctrl + Shift + Alt + V` for Windows
        if (event.keyCode === 86 && event.ctrlKey && event.shiftKey && event.altKey) {
          getBEInfo();
        }
      };
      document.addEventListener('keydown', handleKeyPress);
      return () => {
        document.removeEventListener('keydown', handleKeyPress);
      };
    }
  }, [disabled, getBEInfo]);

  const hideModal = () => {
    setIsModalVisible(false);
  };

  const showDate = (str: string | number | Date) => {
    const d = new Date(str);
    const isValidDate = d && !!d.getDate();
    return isValidDate ? dayjs(str).format() : String(str);
  };

  const sections = {
    'Backend version': {
      Tag: infoBE?.build.tag,
      'Commit Hash': infoBE?.build.commitHash,
      'Deployment Time Stamp': showDate(Number(infoBE?.build?.deploymentTimeStamp) * 1000),
      dataExists: !!infoBE,
    },
    'SSR Backend version': {
      Tag: infoSSR?.tag,
      'Commit Hash': infoSSR?.commitHash,
      'Deployment Time Stamp': infoSSR?.deploymentTimeStamp ? showDate(Number(infoSSR.deploymentTimeStamp) * 1000) : '',
      dataExists: !!infoSSR,
    },
    'Frontend version': {
      Tag: infoFE?.tag,
      'Commit Hash': infoFE?.commitHash,
      'Deployment Time Stamp': infoFE?.deploymentTimeStamp ? showDate(Number(infoFE.deploymentTimeStamp) * 1000) : '',
      dataExists: !!infoFE,
    },
  } as const;

  const preparedSections = project === 'customer' ? sections : omit(sections, ['SSR Backend version']);

  return (
    <>
      {children}
      <Modal title="Versions" open={isModalVisible} onCancel={hideModal} footer={null}>
        <>
          {Object.entries(preparedSections).map(([sectionHeader, data]) => (
            <section key={sectionHeader}>
              <h3>{sectionHeader}</h3>
              <dl>
                {data?.dataExists ? (
                  Object.entries(data)
                    .filter(([label]) => label !== 'dataExists')
                    .map(([label, value]) => (
                      <Fragment key={label}>
                        <dt>{label}</dt>
                        <dd>{value}</dd>
                      </Fragment>
                    ))
                ) : (
                  <p>{"(data for this category cant or shouldn't be displayed)"}</p>
                )}
              </dl>
            </section>
          ))}
        </>
      </Modal>
    </>
  );
}
